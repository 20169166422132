// Copyright 2018 Red Hat, Inc
//
// Licensed under the Apache License, Version 2.0 (the "License"); you may
// not use this file except in compliance with the License. You may obtain
// a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
// WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
// License for the specific language governing permissions and limitations
// under the License.

import React from 'react'
import PropTypes from 'prop-types'


class Role extends React.Component {
  static propTypes = {
    role: PropTypes.object.isRequired
  }

  render() {
    const { role } = this.props
    return (
      <span>
        {role.target_name} ( {role.project_canonical_name})
      </span>
    )
  }
}

export default Role
